<template>
  <div>
    <el-table
      v-loading="form.loading"
      :data="form.model.companyServiceFeatures"
      style="margin-top: 8px; width: 100%"
      stripe
      border
    >
      <el-table-column :label="$tc('name')">
        <template slot-scope="scope">
          {{ scope.row.featureName }}
        </template>
      </el-table-column>
      <el-table-column :label="$tc('quantity')" width="130">
        <template slot-scope="scope">
          <el-input
            v-model.number="scope.row.companyServiceFeatureValue"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('quantityUsed')" width="130">
        <template slot-scope="scope">
          <el-input
            v-model.number="scope.row.companyServiceFeatureValueUsed"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('date')" width="150">
        <template slot-scope="scope">
          <el-date-picker
            v-model="scope.row.companyServiceFeatureDate"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          ></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('dateDue')" width="150">
        <template slot-scope="scope">
          <el-date-picker
            v-model="scope.row.companyServiceFeatureDateDue"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            width="120"
          ></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('price')" width="130">
        <template slot-scope="scope">
          <el-input
            v-model.number="scope.row.companyServiceFeaturePrice"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('status')" align="center" width="80">
        <template slot-scope="scope">
          {{ scope.row.companyServiceFeatureStatus }}
        </template>
      </el-table-column>
      <el-table-column align="center" :width="75">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click.prevent="save(scope.row)"
          >
            <i class="fa fa-save"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
/* app
-------------------------------------------------- */
import form from '@/views/common/form'
import update from '@/views/common/update'
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import model from './model'
import rules from './rules'

/* End of app
-------------------------------------------------- */

/* vue
-------------------------------------------------- */

/* End of vue
-------------------------------------------------- */

/* use
-------------------------------------------------- */
Vue.use(AsyncComputed)

/* End of use
-------------------------------------------------- */

export default {
  extends: update('vouchers', { model }),
  props: {},
  data() {
    return {
      form: form({ model, rules }),
    }
  },
  mounted() {},
  methods: {
    fillForm(value) {
      const _this = this
      _this.form.model = {
        ...value,
      }

      _this.$http
        .get(
          `companyServiceFeatures/${_this.form.model.id}/${_this.form.model.serviceId}/findByCompanyByService`,
        )
        .then(
          result =>
            (_this.form.model.companyServiceFeatures = result.data.filter(
              i =>
                i.companyServiceFeatureStatus === 'vigente' ||
                i.companyServiceFeatureStatus === 'pago',
            )),
        )
    },
    save(companyServiceFeature) {
      const _this = this
      _this.form.loading = true
      _this.$http
        .put(
          `companyServiceFeatures/${companyServiceFeature.id}`,
          companyServiceFeature,
        )
        .then(response => {
          _this.$emit('saved')
          _this.$message({
            message: _this.$tc('processSuccess'),
            type: 'success',
          })
        })
        .finally(_ => {
          _this.form.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
div {
  &::v-deep .cell-padding td {
    padding: 0.2em;
  }
}
div {
  &::v-deep .cell-padding td {
    padding: 0.2em;
  }
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 129px !important;
}
</style>
